import React from "react"

import Card from "./card"

import { readableNumber } from '../../../utils/text';

// growth info
const Growth = ({subreddit, calculated}) => {
  // console.log(subreddit)

  // const growthMetrics = [
  //   {'label': 'Daily New Members', 'value': calculated.per_day_members && parseInt(calculated.per_day_members)},
  //   {'label': 'Monthly Growth', 'value': calculated.growth_members_monthly && calculated.growth_members_monthly.toFixed(2), 'percent': true},
  //   {'label': 'Weekly Growth', 'value': calculated.growth_members_weekly && calculated.growth_members_weekly.toFixed(2), 'percent': true},
  //   {'label': 'Daily Growth', 'value': calculated.growth_members_daily && calculated.growth_members_daily.toFixed(2), 'percent': true},
  // ]

  var growthMetrics = [
    // {'label': 'Daily', 'value': `${readableNumber(calculated.per_day_members || 0)}`, percent: `${(calculated.growth_members_daily || 0).toFixed(1)}`},
    // {'label': 'Monthly', 'value': `${readableNumber(calculated.per_month_members || 0)}`, percent:`${(calculated.growth_members_monthly || 0).toFixed(1)}`},
    {'label': 'Yearly', 'value': `${readableNumber(calculated.per_year_members || 0)}`, percent: `${(calculated.growth_members_yearly || 0).toFixed(1)}`},
  ].filter(m => m.value)

  return (
    <Card title={`Member Growth`} subtitle={`in r/${subreddit.name}`}

    >
      <div className="space-y-2">
        {growthMetrics.map(g => (
          <div key={g.label} className="flex items-center">
            <div className="font-bold">{g.label}</div>
            <div className={`text-lg flex items-center font-semibold ml-auto text-green-500`}>
              <span className={``}>{g.value && g.value[0] !== '-' ? '+' : ''}{g.value || '--'} members</span>
              <span className={`text-gray-500 ml-2 text-xs w-12`}>{`(${g.percent || '--'}%)`}</span>
              {/*{g.percent >= 0 ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
                </svg>
              )}*/}
            </div>
          </div>
        ))}
        
      </div>
    </Card>
  );
}

export default Growth;
