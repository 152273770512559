import React from "react"
import { Link } from "gatsby"

import Card from "./card"

import { readableNumber } from '../../../utils/text';

// similar subreddits for a given subreddit
const Similar = ({subreddit, related}) => {
  return (
    <Card title={`Similar Subreddits`} subtitle={`to r/${subreddit.name}`}>
      <div className="-m-2">
        {related.map(r => (
          <div key={r.data.name} className="p-2 flex items-center rounded-md group">
            <Link to={`/r/${r.data.name}/`} aria-label={`/r/${r.data.name}`} className="bg-gray-700 rounded-md h-16 w-16 mr-4 p-2 flex-shrink-0">
              {r.data.icon ? (
                <img src={r.data.icon} className="h-full w-full rounded-md" alt={`/r/${r.data.name}`}/>
              ) : (
                <svg className="h-full w-full opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                </svg>
              )}
            </Link>
            
            <div className="overflow-hidden">
              <h3 className="truncate">
                <Link to={`/r/${r.data.name}/`} aria-label={`/r/${r.data.name}`} className="border-b-2 border-dotted border-cyan-800 hover:border-cyan-500 overflow-hidden truncate">
                  r/{r.data.name}
                </Link>
              </h3>
              <div className="text-gray-400 flex items-center text-sm">
                <div>{readableNumber(r.data.count_users)} members</div>

                {r.calculated.growth_members_yearly ? (
                  <div className={`flex text-sm items-center ml-2 ${!r.calculated.growth_members_yearly ? 'text-gray-500' : r.calculated.growth_members_yearly > 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {r.calculated.growth_members_yearly >= 0 ? (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
                      </svg>
                    )}

                    <span>{r.calculated.growth_members_yearly.toFixed(1) || '--'}% / yr</span>
                    
                  </div>
                ) : ''}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default Similar;
